<script setup>
const { activeSite, setCurrentEntry, setCurrentSeo } = useSharedState()
const currentUnlocalizedUri = unlocalizedUri()

const { data } = await useAsyncGql({
    operation: 'FlexiblePage',
    variables: {
        lang: activeSite.value.lang,
        slug: 'home',
        urisingle: currentUnlocalizedUri,
        sitesingle: activeSite.value.lang
    },
})

if (data?.value?.entry) {
    setCurrentEntry(data.value.entry)
    setCurrentSeo(data.value.seo)
}
</script>

<template>
    <div>
        <main v-if="data?.entry?.flexible">
            <Flexible :sections="data.entry.flexible" />
        </main>
    </div>
</template>
